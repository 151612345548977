<template>
  <div>
    <Pane />
    <a-card class="container">
      <div class="between">
        <a-space>
          <a-month-picker
            :allowClear="false"
            style="width: 120px"
            v-model="currentMonth"
          />
          <a-input placeholder="部门" v-model="dept" />
          <a-input placeholder="姓名" v-model="name" />
          <a-select style="width: 120px" v-model="view" placeholder="视图">
            <a-select-option
              v-for="item in [
                {
                  name: '日期',
                  value: 'date',
                },
                {
                  name: '状态',
                  value: 'status',
                },
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>

          <a-radio-group v-model="showAll">
            <a-radio :value="true">全部日期</a-radio>
            <a-radio :value="false">只看工作日</a-radio>
          </a-radio-group>

          <a-radio-group v-model="color">
            <a-radio value="all">全部</a-radio>
            <a-radio value="green">正常</a-radio>
            <a-radio value="red">异常</a-radio>
          </a-radio-group>
        </a-space>
        <div>
          <a-button :loading="loadingA" type="primary" @click="downloadAnnual" style="margin-right: 10px;">导出年假数据</a-button>
          <a-button type="primary" @click="download">导出考勤数据</a-button>
        </div>
      </div>

      <Padding />

      <a-table
        bordered
        :data-source="searchList"
        :loading="loading"
        rowKey="userId"
        :scroll="{ x: '3000px' }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record, rowIndex) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record, rowIndex);
                },
              },
            };
          }
        "
      >
        <a-table-column
          key="name"
          title="姓名"
          data-index="name"
        ></a-table-column>
        <a-table-column
          key="deptName"
          title="部门"
          data-index="deptName"
        ></a-table-column>

        <a-table-column
          v-for="item in columns"
          :key="item.key"
          :title="item.title"
          align="center"
        >
          <template slot-scope="text">
            <div class="tags" v-if="showAll ? true : item.needCalculate">
              <template v-if="Array.isArray(text[item.key])">
                <div
                  v-for="(tag, index) in text[item.key]"
                  :key="item.key + tag.title + index"
                  :class="tag.color"
                >
                  <span v-if="view === 'date'">
                    <span v-if="tag.time">{{ tag.time.split(" ")[1] }}</span>
                  </span>
                  <span v-else>{{ tag.title }}</span>
                </div>
              </template>
            </div>
          </template>
        </a-table-column>
      </a-table>

      <a-modal
        :title="detail.name + '考勤详情'"
        :visible="visible"
        @cancel="cancel"
        :footer="null"
      ></a-modal>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import {
  fetchAnalysis,
  fetchLeave,
  fetchCheckApply,
  fetchAllAdjust as fetchAdjustList
} from "@/api/human-resources/clock";
import { getName, isLeapYear } from "@/utils/clock";
import { mapActions, mapState } from "vuex";
import download from "@/api/download";
import downloadByList from "@/utils/xlsx";
import { saveAs } from "file-saver";
function exportFile(data) {
  return download({
    url: "/office-service/att/leave/annualBalanceExport",
    method: "post",
    data
  });
}
export default {
  data() {
    return {
      currentMonth: moment(),
      loadingA: false,
      name: "",
      dept: "",
      color: "all",
      view: undefined,
      showAll: true,

      columns: [],

      loading: false,

      clocks: [],
      leaves: [],
      replaces: [],

      pureList: [],
      overtimeList: [],
      daysoffList: [],
      detail: {},
      visible: false,
    };
  },

  computed: {
    ...mapState("holiday", ["holiday"]),

    filteredList() {
      // 判断是否已经补过卡
      // 再判断是否已经请假出差

      // 再判断昨天是否加班
      // 再判断用户有没有打卡，打卡有没有迟到早退
      // 再判断是否有哺乳假

      let result = JSON.parse(JSON.stringify(this.pureList));

      return result.map((item) => {
        const obj = { ...item };
        // 员工加班记录
        const overtimeList = this.overtimeList.filter(
          (v) => v.userId === item.userId
        );
        if(overtimeList.length > 0) {
          console.log(overtimeList)
        }
        
        overtimeList.forEach(item=>{
          obj[item.date] && obj[item.date].unshift({
            title:moment(item.startTime).format("HH:mm") + '-' + moment(item.endTime).format("HH:mm")  + getName(item.type) + this.getStatus(item.status) + (item.effectiveStatus=='invalid' && item.status=='approved'?'待生效':''),
            color: "green",
            date: item.date,
            type: item.type
          });
        })
        //员工调休记录
        const daysoffList = this.daysoffList.filter(
          (v) => v.userId === item.userId
        );
        daysoffList.forEach(item=>{
          const startDateObject = moment(item.startTime, "YYYY-MM-DD");
          const endDateObject = moment(item.endTime, "YYYY-MM-DD");
          if (
            moment(item.startTime).isSameOrBefore(
              startDateObject.format("YYYY-MM-DD") + " 09:00:00"
            ) &&
            moment(item.endTime).isSameOrAfter(
              endDateObject.format("YYYY-MM-DD") + " 17:30:00"
            )
          ){
            obj[item.date] && obj[item.date].unshift({
              title: getName(item.type) + this.getStatus(item.status),
              color: "green",
              date: item.date,
            });
          }  else if (
            moment(item.startTime).isSameOrBefore(
              startDateObject.format("YYYY-MM-DD") + " 09:00:00"
            ) &&
            moment(item.endTime).isSameOrAfter(
              endDateObject.format("YYYY-MM-DD") + " 12:00:00"
            )
          ) {
            // 开始和结束时间包含了9点，说明上午在调休
            obj[item.date].unshift({
              title:
                "上午" + getName(item.type) + this.getStatus(item.status),
              type: "morning",
              color: "green",
              date: item.date,
            });
          } else if (
            moment(item.startTime).isSameOrBefore(
              startDateObject.format("YYYY-MM-DD") + " 13:30:00"
            ) &&
            moment(item.endTime).isSameOrAfter(
              endDateObject.format("YYYY-MM-DD") + " 17:30:00"
            )
          ) {
            // 开始和结束时间包含了下午5点半，说明下午在调休
            obj[item.date].unshift({
              title:
                "下午" + getName(item.type) + this.getStatus(item.status),
              type: "afternoon",
              color: "green",
              date: item.date,
            });
          } else {
            obj[item.date].unshift({
              title:moment(item.startTime).format("HH:mm") + '-' + moment(item.endTime).format("HH:mm")  + getName(item.type) + this.getStatus(item.status),
              type: "days_off",
              color: "green",
              date: item.date,
              startTime: item.date + ' ' + moment(item.startTime).format("HH:mm"),
              endTime: item.date + ' ' + moment(item.endTime).format("HH:mm")
            });
          }
        })

        // 员工补卡记录
        const userReplaces = this.replaces.filter(
          (leave) => leave.userId === item.userId
        );
        for (let i = 0; i < userReplaces.length; i++) {
          const rep = userReplaces[i];
          const dateStr = rep.checkTime.split(" ")[0];
          if (!Array.isArray(obj[dateStr])) {
            continue;
          }

          if (rep.checkType === "OnDuty") {
            obj[dateStr].push({
              title: "上班已补卡",
              color: "green",
              type: "morning",
            });
          } else if (rep.checkType === "OffDuty") {
            obj[dateStr].push({
              title: "下班已补卡",
              color: "green",
              type: "afternoon",
            });
          }
        }
        //员工请假出差记录
        const userLeaves = this.leaves.filter(
          (leave) => leave.userId === item.userId
        );
        for (let i = 0; i < userLeaves.length; i++) {
          const leave = userLeaves[i];
          const startDateObject = moment(leave.startTime);
          const endDateObject = moment(leave.endTime);

          Object.keys(obj).forEach((key) => {
            if (Array.isArray(obj[key])) {
              if (
                startDateObject.isSameOrBefore(key, "days") &&
                endDateObject.isSameOrAfter(key, "days")
              ) {
                const startTimeStr = startDateObject.format("HH:mm:ss");
                const endTimeStr = endDateObject.format("HH:mm:ss");

                if (
                  startDateObject.format("YYYY-MM-DD") ===
                  endDateObject.format("YYYY-MM-DD")
                ) {
                  console.log("就一天假");

                  if (leave.type === "on_business_in") {
                    if (
                      startDateObject.isSameOrBefore(
                        startDateObject.format("YYYY-MM-DD") + " 09:00:00"
                      ) &&
                      endDateObject.isSameOrAfter(
                        endDateObject.format("YYYY-MM-DD") + " 17:30:00"
                      )
                    ) {
                      // 开始和结束时间包括了9点和下午5点半，说明这一天都在公出
                      obj[key].unshift({
                        title:
                          getName(leave.type) + this.getStatus(leave.status),
                        color: "green",
                        date: key,
                      });
                    } else if (
                      startDateObject.isSameOrBefore(
                        startDateObject.format("YYYY-MM-DD") + " 09:00:00"
                      ) &&
                      endDateObject.isSameOrAfter(
                        endDateObject.format("YYYY-MM-DD") + " 09:00:00"
                      )
                    ) {
                      // 开始和结束时间包含了9点，说明上午在公出
                      obj[key].unshift({
                        title:
                          "上午" +
                          getName(leave.type) +
                          this.getStatus(leave.status),
                        color: "green",
                        type: "morning",
                        date: key,
                      });
                    } else if (
                      startDateObject.isSameOrBefore(
                        startDateObject.format("YYYY-MM-DD") + " 17:30:00"
                      ) &&
                      endDateObject.isSameOrAfter(
                        endDateObject.format("YYYY-MM-DD") + " 17:30:00"
                      )
                    ) {
                      // 开始和结束时间包含了下午5点半，说明下午在公出

                      obj[key].unshift({
                        title:
                          "下午" +
                          getName(leave.type) +
                          this.getStatus(leave.status),
                        color: "green",
                        type: "afternoon",
                        date: key,
                      });
                    } else {
                      console.log(
                        `公出在${startDateObject.format(
                          "YYYY-MM-DD"
                        )}内出现，不参与考勤`
                      );
                    }

                    return;
                  }

                  if (
                    startTimeStr === "09:00:00" &&
                    endTimeStr === "17:30:00"
                  ) {
                    obj[key].unshift({
                      title: getName(leave.type) + this.getStatus(leave.status),
                      color: "green",
                      date: key,
                    });
                  } else {
                    if (startTimeStr !== "09:00:00") {
                      obj[key].unshift({
                        title:
                          "下午" +
                          getName(leave.type) +
                          this.getStatus(leave.status),
                        color: "green",
                        type: "afternoon",
                        date: key,
                      });
                    } else {
                      obj[key].unshift({
                        title:
                          "上午" +
                          getName(leave.type) +
                          this.getStatus(leave.status),
                        color: "green",
                        type: "morning",
                        date: key,
                      });
                    }
                  }
                } else {
                  if (startDateObject.isSame(key, "days")) {
                    if (leave.type === "on_business_in") {
                      if (
                        startDateObject.isSameOrBefore(
                          startDateObject.format("YYYY-MM-DD") + " 09:00:00"
                        )
                      ) {
                        // 第一天全是公出
                        obj[key].unshift({
                          title:
                            getName(leave.type) + this.getStatus(leave.status),
                          color: "green",
                          date: key,
                        });
                      } else if (
                        startDateObject.isAfter(
                          startDateObject.format("YYYY-MM-DD") + " 09:00:00"
                        ) &&
                        startDateObject.isSameOrBefore(
                          startDateObject.format("YYYY-MM-DD") + " 17:30:00"
                        )
                      ) {
                        // 再判断一下是否是9点之后，5点半之前，如果是的话，就是下午开始公出

                        obj[key].unshift({
                          title:
                            "下午" +
                            getName(leave.type) +
                            this.getStatus(leave.status),
                          color: "green",
                          type: "afternoon",
                          date: key,
                        });
                      } else {
                        console.log(
                          "第一天是下午5点半之后，随便他是几点申请公出"
                        );
                      }

                      return;
                    }

                    // console.log("第一天");
                    if (startTimeStr === "09:00:00") {
                      obj[key].unshift({
                        title:
                          getName(leave.type) + this.getStatus(leave.status),
                        color: "green",
                        date: key,
                      });
                    } else {
                      obj[key].unshift({
                        title:
                          "下午" +
                          getName(leave.type) +
                          this.getStatus(leave.status),
                        color: "green",
                        type: "afternoon",
                        date: key,
                      });
                    }
                  } else if (endDateObject.isSame(key, "days")) {
                    // console.log("最后一天");

                    if (leave.type === "on_business_in") {
                      if (
                        endDateObject.isSameOrAfter(
                          endDateObject.format("YYYY-MM-DD") + " 17:30:00"
                        )
                      ) {
                        // 公出最后一天，结束时间包括了下午5点半，说明这一天都在公出
                        obj[key].unshift({
                          title:
                            getName(leave.type) + this.getStatus(leave.status),
                          color: "green",
                          date: key,
                        });
                      } else if (
                        endDateObject.isBefore(
                          endDateObject.format("YYYY-MM-DD") + " 17:30:00"
                        ) &&
                        endDateObject.isSameOrAfter(
                          endDateObject.format("YYYY-MM-DD") + " 09:00:00"
                        )
                      ) {
                        // 最后一天只要是5点半之前，9点或9点之后，就是上午结束了公出
                        obj[key].unshift({
                          title:
                            "上午" +
                            getName(leave.type) +
                            this.getStatus(leave.status),
                          color: "green",
                          type: "morning",
                          date: key,
                        });
                      } else {
                        console.log("最后一天公出是9点之前，忽略掉这种");
                      }
                      return;
                    }

                    if (endTimeStr === "17:30:00") {
                      obj[key].unshift({
                        title:
                          getName(leave.type) + this.getStatus(leave.status),
                        color: "green",
                        date: key,
                      });
                    } else {
                      obj[key].unshift({
                        title:
                          "上午" +
                          getName(leave.type) +
                          this.getStatus(leave.status),
                        color: "green",
                        type: "morning",
                        date: key,
                      });
                    }
                  } else {
                    // console.log("中间的假期");

                    obj[key].unshift({
                      title: getName(leave.type) + this.getStatus(leave.status),
                      color: "green",
                      date: key,
                    });
                  }
                }
              }
            }
          });
        }

        // 员工打卡记录
        const userList = this.clocks.filter(
          (clock) => clock.userId === item.userId
        );

        let userClocks = [];
        if (userList[0] && Array.isArray(userList[0].attList)) {
          const attList = userList[0].attList.filter((item) => !!item);

          attList.forEach((att) => {
            const index = userClocks.findIndex(
              (element) => element.workDate === att.workDate
            );
            if (index === -1) {
              userClocks.push({
                workDate: att.workDate,
                list: [att],
              });
            } else {
              if (att.checkType === "OnDuty") {
                userClocks[index].list.unshift(att);
              } else {
                userClocks[index].list.push(att);
              }
            }
          });
        }

        for (let i = 0; i < userClocks.length; i++) {
          const clock = userClocks[i];

          if (!Array.isArray(obj[clock.workDate])) {
            continue;
          }

          const goToWorkTimeObject = moment(
            clock.workDate + " 09:00:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goToWorkTimeLate5Object = moment(
            clock.workDate + " 09:05:00",
            "YYYY-MM-DD HH:mm:ss"
          );
          const goToWorkTimeLate30Object = moment(
            clock.workDate + " 09:30:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goToWorkTimeLate10Object = moment(
            clock.workDate + " 10:00:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goToWorkTimeHalfDayObject = moment(
            clock.workDate + " 13:30:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goOffWorkTimeObject = moment(
            clock.workDate + " 17:30:00",
            "YYYY-MM-DD HH:mm:ss"
          );
          const goOffWorkTimeEarlyObject = moment(
            clock.workDate + " 17:00:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const feedObj = obj[clock.workDate].find(
            (element) =>
              element.title === "哺乳假" && element.date === clock.workDate
          );

          const otherObj = obj[clock.workDate].find(
            (element) =>
              element.title !== "哺乳假" && element.date === clock.workDate
          );

          if (feedObj) {
            // 如果有哺乳假，那单独走这套判断逻辑
            console.log("如果有哺乳假，那单独走这套判断逻辑");
            let comeDate = "";
            let goDate = "";

            for (let i = 0; i < clock.list.length; i++) {
              const element = clock.list[i];
              if (element.checkType === "OnDuty") {
                comeDate = element.userCheckTime;
              } else if (element.checkType === "OffDuty") {
                goDate = element.userCheckTime;
              }
            }

            if (otherObj) {
              // 如果这个人这天有假期，如果是一天的假期，页面上会直接覆盖，其实不用处理，如果是半天的假期，就需要判断了。
              if (otherObj.title.indexOf("上午") > -1) {
                // 上午的假期，判断下午是否早退即可，如果没打卡，这个函数结束之前会走判断逻辑
                if (goDate) {
                  // 打了下班卡
                  const goDiff = moment(goDate).diff(
                    goOffWorkTimeObject,
                    "minutes"
                  );
                  if (goDiff < 0) {
                    // 下班早退

                    if (Math.abs(goDiff) > 60) {
                      obj[clock.workDate].push({
                        title: "下班异常",
                        color: "red",
                        time: goDate,
                        reason: "哺乳假期间",
                        type: "afternoon",
                        category: "clock",
                      });
                    } else {
                      obj[clock.workDate].push({
                        title: "下班正常",
                        color: "green",
                        time: goDate,
                        reason: "哺乳假期间",
                        type: "afternoon",
                        category: "clock",
                      });
                    }
                  } else {
                    obj[clock.workDate].push({
                      title: "下班正常",
                      color: "green",
                      time: goDate,
                      reason: "哺乳假期间",
                      type: "afternoon",
                      category: "clock",
                    });
                  }
                }
              } else if (otherObj.title.indexOf("下午") > -1) {
                if (comeDate) {
                  const comeDiff = moment(comeDate).diff(
                    goToWorkTimeObject,
                    "minutes"
                  );

                  if (comeDiff > 0) {
                    if (comeDiff > 60) {
                      console.log(comeDate + "出发了半天假期的上班异常");
                      obj[clock.workDate].push({
                        title: "上班异常",
                        color: "red",
                        time: comeDate,
                        reason: "哺乳假期间",
                        type: "morning",
                        category: "clock",
                      });
                    } else {
                      obj[clock.workDate].push({
                        title: "上班正常",
                        color: "green",
                        time: comeDate,
                        reason: "哺乳假期间",
                        type: "morning",
                        category: "clock",
                      });
                    }
                  } else {
                    obj[clock.workDate].push({
                      title: "上班正常",
                      color: "green",
                      time: comeDate,
                      reason: "哺乳假期间",
                      type: "morning",
                      category: "clock",
                    });
                  }
                }
              }
            } else {
              if (comeDate && goDate) {
                // 判断上班时间和9点的分钟差值，如果早于9点是负数
                const comeDiff = moment(comeDate).diff(
                  goToWorkTimeObject,
                  "minutes"
                );
                const goDiff = moment(goDate).diff(
                  goOffWorkTimeObject,
                  "minutes"
                );

                if (comeDiff <= 0 && goDiff >= 0) {
                  // 上下班都正常

                  obj[clock.workDate].push(
                    {
                      title: "上班正常",
                      color: "green",
                      time: comeDate,
                      reason: "",
                      type: "morning",
                      category: "clock",
                    },
                    {
                      title: "下班正常",
                      color: "green",
                      time: goDate,
                      reason: "",
                      type: "afternoon",
                      category: "clock",
                    }
                  );
                } else if (comeDiff > 0 && goDiff >= 0) {
                  // 下班正常，上班迟到
                  let newObj;
                  if (comeDiff > 60) {
                    newObj = {
                      title: "上班异常",
                      color: "red",
                      time: comeDate,
                      reason: "哺乳假期间",
                      type: "morning",
                      category: "clock",
                    };
                  } else {
                    newObj = {
                      title: "上班正常",
                      color: "green",
                      time: comeDate,
                      reason: "哺乳假期间",
                      type: "morning",
                      category: "clock",
                    };
                  }

                  obj[clock.workDate].push(newObj, {
                    title: "下班正常",
                    color: "green",
                    time: goDate,
                    reason: "",
                    type: "afternoon",
                    category: "clock",
                  });
                } else if (comeDiff <= 0 && goDiff < 0) {
                  // 下班早退，上班正常
                  let newObj;

                  if (Math.abs(goDiff) > 60) {
                    newObj = {
                      title: "下班异常",
                      color: "red",
                      time: goDate,
                      reason: "哺乳假期间",
                      type: "afternoon",
                      category: "clock",
                    };
                  } else {
                    newObj = {
                      title: "下班正常",
                      color: "green",
                      time: goDate,
                      reason: "哺乳假期间",
                      type: "afternoon",
                      category: "clock",
                    };
                  }

                  obj[clock.workDate].push(
                    {
                      title: "上班正常",
                      color: "green",
                      time: comeDate,
                      reason: "",
                      type: "morning",
                      category: "clock",
                    },
                    newObj
                  );
                } else {
                  // 上下班都有问题
                  const diff = comeDiff + Math.abs(goDiff);
                  if (diff > 60) {
                    obj[clock.workDate].push(
                      {
                        title: "上班异常",
                        color: "red",
                        time: comeDate,
                        reason: "哺乳假期间",
                        type: "morning",
                        category: "clock",
                      },
                      {
                        title: "下班正常",
                        color: "green",
                        time: goDate,
                        reason: "哺乳假期间",
                        type: "afternoon",
                        category: "clock",
                      }
                    );
                  } else {
                    obj[clock.workDate].push(
                      {
                        title: "上班正常",
                        color: "green",
                        time: comeDate,
                        reason: "哺乳假期间",
                        type: "morning",
                        category: "clock",
                      },
                      {
                        title: "下班正常",
                        color: "green",
                        time: goDate,
                        reason: "哺乳假期间",
                        type: "afternoon",
                        category: "clock",
                      }
                    );
                  }
                }
              } else if (comeDate && !goDate) {
                // 判断上班时间和9点的分钟差值，如果早于9点是负数
                const comeDiff = moment(comeDate).diff(
                  goToWorkTimeObject,
                  "minutes"
                );
                let newObj;

                if (comeDiff > 60) {
                  newObj = {
                    title: "上班异常",
                    color: "red",
                    time: comeDate,
                    reason: "哺乳假期间",
                    type: "morning",
                    category: "clock",
                  };
                } else {
                  newObj = {
                    title: "上班正常",
                    color: "green",
                    time: comeDate,
                    reason: "哺乳假期间",
                    type: "morning",
                    category: "clock",
                  };
                }

                obj[clock.workDate].push(newObj, {
                  title: "下班未打卡",
                  color: "red",
                  type: "afternoon",
                  category: "clock",
                });
              } else if (!comeDate && goDate) {
                const goDiff = moment(goDate).diff(
                  goOffWorkTimeObject,
                  "minutes"
                );
                let newObj;

                if (goDiff < 0 && Math.abs(goDiff) > 60) {
                  newObj = {
                    title: "下班异常",
                    color: "red",
                    time: goDate,
                    reason: "哺乳假期间",
                    type: "afternoon",
                    category: "clock",
                  };
                } else {
                  newObj = {
                    title: "下班正常",
                    color: "green",
                    time: goDate,
                    reason: "哺乳假期间",
                    type: "afternoon",
                    category: "clock",
                  };
                }

                obj[clock.workDate].push(
                  {
                    title: "上班未打卡",
                    color: "red",
                    type: "morning",
                    category: "clock",
                  },
                  newObj
                );
              } else {
                console.log("checkType 没匹配上，不可能发生");
              }
            }
          } else {
            for (let i = 0; i < clock.list.length; i++) {
              const element = clock.list[i];

              const userCheckTime = moment(
                element.userCheckTime,
                "YYYY-MM-DD HH:mm:ss"
              );

              if (element.checkType === "OnDuty") {
                if (
                  userCheckTime.isSameOrBefore(goToWorkTimeObject, "minutes")
                ) {
                  obj[clock.workDate].push({
                    title: "上班正常",
                    color: "green",
                    category: "clock",
                    time: element.userCheckTime,
                    type: "morning",
                  });
                } else {
                  let yesterday;

                  const overtime = clock.list.find(
                    (element) =>
                      element.workDate === clock.workDate &&
                      element.checkType === "OverTime"
                  );

                  if (overtime) {
                    console.log(
                      clock.workDate + "有凌晨加班" + overtime.userCheckTime
                    );

                    yesterday = {
                      ...overtime,
                      isSecond: true,
                    };
                  }

                  if (!yesterday) {
                    const yesterdayStr = moment(clock.workDate)
                      .clone()
                      .subtract(1, "days")
                      .format("YYYY-MM-DD");
                    // 可以直接获取到上月最后一天
                    // 周一暂时不考虑，打工人都休息2天了，还要啥合法迟到

                    const yesterdayObj = userClocks.find(
                      (c) => c.workDate === yesterdayStr
                    );
                    if (yesterdayObj && Array.isArray(yesterdayObj.list)) {
                      const yes = yesterdayObj.list.find(
                        (yes) => yes.checkType === "OffDuty"
                      );

                      // 钉钉返回的数据正常的 OffDuty，但打卡时间是次日凌晨
                      let isSecond = false;

                      if (yes) {
                        isSecond = moment(yes.userCheckTime).isAfter(
                          yes.workDate,
                          "days"
                        );

                        yesterday = {
                          ...yes,
                          isSecond,
                        };
                      }
                    }
                  }

                  let newTime = "";

                  const { userCheckTime: yesterdayUserCheckTime, isSecond } =
                    yesterday || {};

                  if (yesterdayUserCheckTime) {
                    console.log(
                      "yesterdayUserCheckTime",
                      yesterdayUserCheckTime
                    );
                    const yesterdayUserCheckTimeObj = moment(
                      yesterdayUserCheckTime
                    );

                    let yesterdayCheckTimeObj; // 加班判断依据

                    if (isSecond) {
                      yesterdayCheckTimeObj = moment(
                        yesterdayUserCheckTime
                      ).subtract(1, "days");
                    } else {
                      yesterdayCheckTimeObj = moment(yesterdayUserCheckTime);
                    }

                    const yesterday2130 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "21:00:00",
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const yesterday2200 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "22:00:00",
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const yesterday2300 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "23:00:00",
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const yesterday2359 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "23:59:59",
                      "YYYY-MM-DD HH:mm:ss"
                    );

                    if (
                      yesterdayUserCheckTimeObj.isSameOrAfter(yesterday2130)
                    ) {
                      if (yesterdayUserCheckTimeObj.isBefore(yesterday2200)) {
                        console.log("下班时间在9点到10点之间");
                        newTime = "09:30";
                      } else if (
                        yesterdayUserCheckTimeObj.isBefore(yesterday2300)
                      ) {
                        console.log("下班时间在10点到11点之间");
                        newTime = "10:00";
                      } else if (
                        yesterdayUserCheckTimeObj.isSameOrBefore(yesterday2359)
                      ) {
                        console.log("下班时间在11点到12点之间");
                        newTime = "11:00";
                      } else {
                        console.log(
                          "下班时间在12点之后，不知道钉钉可不可以打卡"
                        );
                        newTime = "13:30";
                      }
                    }
                  }
                  if (newTime) {
                    if (newTime === "09:30") {
                      if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate30Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "昨天上班至" + yesterdayUserCheckTime,
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate10Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "迟到",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      }
                    } else if (newTime === "10:00") {
                      const condition = clock.workDate + " 10:00:00";

                      if (userCheckTime.isSameOrBefore(condition, "minutes")) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "昨天上班至" + yesterdayUserCheckTime,
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      }
                    } else if (newTime === "11:00") {
                      const condition = clock.workDate + " 11:00:00";

                      if (userCheckTime.isSameOrBefore(condition, "minutes")) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "昨天上班至" + yesterdayUserCheckTime,
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      }
                    } else {
                      obj[clock.workDate].push({
                        title: "上午不用上班",
                        color: "green",
                        category: "clock",
                        reason: "昨天上班至" + yesterdayUserCheckTime,
                        time: element.userCheckTime,
                        type: "morning",
                      });
                    }
                  } else {
                    if (
                      typeof item.attGroupId === "string" &&
                      item.attGroupId.indexOf("org_product") > -1 &&
                      userCheckTime.isBefore("2023-09-01", "days")
                    ) {
                      console.log("生产部门的人上班判断逻辑");
                      if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate30Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "生产部门9点半之前算正常",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate10Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "迟到",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      }
                    } else {
                      // 普通员工的上班判断逻辑
                      if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate5Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "5分钟内迟到",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate10Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "迟到",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning",
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,

                          type: "morning",
                        });
                      }
                    }
                  }
                }
              } else if (element.checkType === "OffDuty") {
                if (userCheckTime.isSameOrAfter(goOffWorkTimeObject)) {
                  obj[clock.workDate].push({
                    title: "下班正常",
                    color: "green",
                    category: "clock",
                    time: element.userCheckTime,
                    type: "afternoon",
                  });
                } else {
                  // 普通员工的下班判断逻辑
                  if (
                    userCheckTime.isBefore(goOffWorkTimeObject) &&
                    userCheckTime.isSameOrAfter(goOffWorkTimeEarlyObject)
                  ) {
                    obj[clock.workDate].push({
                      title: "早退",
                      color: "red",
                      category: "clock",
                      reason: "5点之后5点半之前打卡，算早退",
                      time: element.userCheckTime,
                      type: "afternoon",
                    });
                  } else {
                    obj[clock.workDate].push({
                      title: "下午旷工半日",
                      color: "red",
                      category: "clock",
                      reason: "5点之前下班，过早，算旷工半日",
                      time: element.userCheckTime,
                      type: "afternoon",
                    });
                  }
                }
              } else if (element.checkType === "OverTime") {
                if (
                  userCheckTime.isSameOrBefore(
                    userCheckTime.format("YYYY-MM-DD") + " 06:00:00",
                    "seconds"
                  )
                ) {
                  const key = userCheckTime
                    .clone()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");

                  console.log("key", key);

                  if (
                    obj[key] &&
                    !obj[key].find((item) => item.title === "下班正常")
                  ) {
                    obj[key].push({
                      title: "下班正常",
                      color: "green",
                      category: "clock",
                      time: element.userCheckTime,
                      type: "afternoon",
                    });
                  }
                }
              }
            }
          }
        }

        const today = moment();

        Object.keys(obj).forEach((key) => {
          if (Array.isArray(obj[key])) {
            const length = obj[key].filter(
              (e) => e.category === "clock"
            ).length;

            if (length === 0) {
              obj[key].push(
                {
                  title: "上班未打卡",
                  color: "red",
                  type: "morning",
                  category: "clock",
                },
                {
                  title: "下班未打卡",
                  color: "red",
                  type: "afternoon",
                  category: "clock",
                }
              );
            } else if (length === 1) {
              if (obj[key][0].type === "morning") {
                obj[key].push({
                  title: "下班未打卡",
                  color: "red",
                  type: "afternoon",
                  category: "clock",
                });
              } else if (obj[key][0].type === "afternoon") {
                obj[key].push({
                  title: "上班未打卡",
                  color: "red",
                  type: "morning",
                  category: "clock",
                });
              }
            }

            obj[key].forEach((item,index)=>{
              const daysOff = daysoffList.filter(v=>v.date === key)
              if(item.title == '迟到'){
                if(daysoffList.length > 0){
                  daysOff.forEach(el=>{
                    if(el.status == 'approved' && moment(item.time).isSameOrBefore(el.endTime) && moment(item.time).isSameOrAfter(el.startTime)){
                      console.log(11111)
                      obj[key][index].title = '上班正常'
                      obj[key][index].color = 'green'
                    }
                  })
                }
              }
              if(item.title == '早退'){
                if(daysoffList.length > 0){
                  daysOff.forEach(el=>{
                    if(el.status == 'approved' && moment(item.time).isSameOrBefore(el.endTime) && moment(item.time).isSameOrAfter(el.startTime)){
                      console.log(11111)
                      obj[key][index].title = '下班正常'
                      obj[key][index].color = 'green'
                    }
                  })
                }
              }
            })
            // 上午迟到未打卡如果有上午的补卡记录或请求出差记录，直接替换
            let morning = obj[key].filter((item) => item.type === "morning");

            const greenMorning = morning.filter(
              (item) => item.color === "green"
            );
            if (greenMorning.length > 0) {
              morning = greenMorning;
            }

            // 下午迟到未打卡如果有下午的补卡记录或请求出差记录，直接替换
            let afternoon = obj[key].filter(
              (item) => item.type === "afternoon"
            );

            const greenAfternoon = afternoon.filter(
              (item) => item.color === "green"
            );
            if (greenAfternoon.length > 0) {
              afternoon = greenAfternoon;
            }

            // 整天假期
            const others = obj[key].filter(
              (item) => !item.type && item.color === "green" || item.type === "overtime" || item.type === "days_off"
            );
            const noFeed = others.filter((item) => item.title !== "哺乳假" && item.type !== "overtime" && item.type !== "days_off");
            if (noFeed.length > 0) {
              // 有了除哺乳假之外的其他整天假期，那就不用显示其他的上班/下班迟到早退未打卡了，如果有半天的假期，也会被隐藏，这个无所谓，理论上假期不允许重叠。
              obj[key] = others;
            } else {
              obj[key] = [...morning, ...afternoon, ...others];
            }

            if (today.isBefore(key, "days")) {
              // console.log(key + "还没到这天，忽略掉");
              obj[key] = obj[key].filter((item) => item.color !== "red");
            } else if (today.isSame(key, "days")) {
              if (today.isBefore(key + " 08:30:00", "minutes")) {
                // console.log(key + "还没到上班时间");
                obj[key] = obj[key].filter((item) => item.color !== "red");
              } else if (today.isBefore(key + " 17:30:00", "minutes")) {
                // console.log(key + "还没到下班时间，过滤掉下午异常的打卡记录");
                obj[key] = obj[key].filter(
                  (item) => !(item.color === "red" && item.type === "afternoon")
                );
              }
            }
          }
        });

        return obj;
      });
    },

    searchList() {
      let result = this.filteredList;
      if (this.name) {
        result = result.filter(
          (item) => item.name && item.name.indexOf(this.name) > -1
        );
      }
      if (this.dept) {
        result = result.filter(
          (item) => item.deptName && item.deptName.indexOf(this.dept) > -1
        );
      }

      if (this.color === "all") {
        return result;
      } else {
        return result.filter((item) => {
          const keys = Object.keys(item);

          let redFlag = false;

          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (Array.isArray(item[key])) {
              if (this.showAll) {
                if (item[key].filter((obj) => obj.color === "red").length > 0) {
                  redFlag = true;
                  break;
                }
              } else {
                const column = this.columns.find((col) => col.key === key);
                if (column.needCalculate) {
                  if (
                    item[key].filter((obj) => obj.color === "red").length > 0
                  ) {
                    redFlag = true;
                    break;
                  }
                }
              }
            }
          }

          if (this.color === "green") {
            return !redFlag;
          } else if (this.color === "red") {
            return redFlag;
          }
          return true;
        });
      }
    },
  },

  watch: {
    currentMonth() {
      this.init();
    },
  },

  mounted() {
    this.getHoliday().then(() => {
      this.init();
    });
  },

  methods: {
    ...mapActions("holiday", ["getHoliday"]),

    isHoliday(date) {
      let status = "";

      loop: for (let i = 0; i < this.holiday.length; i++) {
        const item = this.holiday[i];
        if (Array.isArray(item.data)) {
          for (let j = 0; j < item.data.length; j++) {
            const element = item.data[j];

            if (
              date.isSameOrAfter(element.startHoliday, "day") &&
              date.isSameOrBefore(element.endHoliday, "day")
            ) {
              status = "holiday";
              break loop;
            }

            for (let x = 0; x < element.workday.length; x++) {
              if (date.isSame(element.workday[x], "day")) {
                status = "workday";
                break loop;
              }
            }
          }
        }
      }

      return status;
    },

    getStatus(status) {
      if (status === "reviewing") {
        return "审批中";
      } else if (status === "approved") {
        return "";
      } else {
        return "";
      }
    },

    init() {
      const year = this.currentMonth.year();
      const month = this.currentMonth.month() + 1;

      let days = 0;

      if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) > -1) {
        days = 31;
      } else if ([4, 6, 9, 11].indexOf(month) > -1) {
        days = 30;
      } else if (month === 2) {
        if (isLeapYear(year)) {
          days = 29;
        } else {
          days = 28;
        }
      }

      const columns = [];

      for (let i = 1; i <= days; i++) {
        const date = new Date(year, month - 1, i);
        const week = date.getDay();
        const dateObj = moment(date);
        const status = this.isHoliday(dateObj);

        let needCalculate = true;
        if (status === "holiday") {
          needCalculate = false;
        } else if (status === "workday") {
          needCalculate = true;
        } else {
          if (week === 0 || week === 6) {
            needCalculate = false;
          } else {
            needCalculate = true;
          }
        }

        columns.push({
          key: dateObj.format("YYYY-MM-DD"),
          title: i + "号",
          needCalculate,
        });
      }
      this.columns = columns;

      this.getData();
      this.getLeave();
      this.getReplace();
      this.getAdjust('overtime')
      this.getAdjust('days_off')
    },
    // 获取加班调休数据
    getAdjust(type) {
      const m = this.currentMonth;
      const startTime = m.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime = m.endOf("month").format("YYYY-MM-DD") + " 23:59:59";
      fetchAdjustList({
        startTime:startTime,
        endTime:endTime,
        type: type
      }).then((res) => {
        let arr = []
        res.forEach((v)=>{
          if(v.extra){
            const extra = JSON.parse(v.extra)
            extra.forEach(item=>{
              arr.push({
                ...v,
                date: item.date,
                startTime: item.date + ' ' + item.startTime,
                endTime: item.date + ' ' + item.endTime,
                effectiveStatus: item.effectiveStatus
              })
            })
          }
        })
        console.log(arr)
        if(type == 'overtime'){
          this.overtimeList = arr
        }
        if(type == 'days_off'){
          this.daysoffList = arr
        }
      })
    },
    getData() {
      this.loading = true;
      fetchAnalysis({
        workDate: this.currentMonth.format("YYYY-MM"),
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.clocks = Object.freeze(res);

            const obj = {};
            this.columns.forEach((item) => {
              // if (item.needCalculate) {
              obj[item.key] = [];
              // }
            });

            this.pureList = res.map((item) => {
              return {
                userId: item.userId,
                name: item.name,
                deptName: item.deptName,
                attGroupId: item.attGroupId,

                ...obj,
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getLeave() {
      const startTime =
        this.currentMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime =
        this.currentMonth.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      fetchLeave({
        startTime,
        endTime,
        statusList: ["reviewing", "approved"],
      }).then((res) => {
        if (Array.isArray(res)) {
          this.leaves = Object.freeze(
            res.filter((item) => item.optType !== "cancel")
          );
        }
      });
    },

    getReplace() {
      const startTime =
        this.currentMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime =
        this.currentMonth.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      fetchCheckApply({
        startTime,
        endTime,
        applyStatusList: ["reviewing", "approved"],
      }).then((res) => {
        if (Array.isArray(res)) {
          this.replaces = Object.freeze(res);
        }
      });
    },

    onTableRowClick(record, index) {
      console.log("reocrd", record, index);
      // this.detail = record;
      // this.visible = true;
    },
    cancel() {
      this.detail = {};
      this.visible = false;
    },
    downloadAnnual() {
      this.loadingA = true;
      exportFile([])
        .then(blob => {
          saveAs(blob, `年假数据.xlsx`);
        }).finally(() => {
          this.loadingA = false;
        });
    },
    download() {
      let keys = [];
      if (this.showAll) {
        keys = this.columns.map((item) => item.key);
      } else {
        keys = this.columns
          .filter((item) => item.needCalculate)
          .map((item) => item.key);
      }

      const result = this.searchList.map((item) => {
        const obj = {
          姓名: item.name,
          部门: item.deptName,
        };

        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            if (keys.indexOf(key) > -1) {
              if (this.view === "date") {
                obj[key] = item[key]
                  .map((element) => {
                    let time = "";
                    if (element.time) {
                      time = element.time.split(" ")[1];
                    }
                    return time;
                  })
                  .filter((item) => item)
                  .join("；");
              } else {
                obj[key] = item[key].map((element) => element.title).join("；");
              }
            } else {
              obj[key] = "";
            }
          }
        });

        return obj;
      });
      console.log("result", result);
      downloadByList(result, this.currentMonth.format("M") + "月考勤数据");
    },
  },
};
</script>


<style lang="less" scoped>
.tags {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.green {
  color: #02a42f;
  text-align: center;
}

.red {
  text-align: center;
  color: #ff3535;
}
</style>